import MediaViewer from '@pages/MediaViewer';
import React, { ReactElement, useRef, useState } from 'react';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import { Route, Routes } from 'react-router-dom';
import {
  AssetReviewProvider,
  useAssetReview
} from '@components/AssetReviewProvider';
import LinkProtected from '@pages/ExternalReviewer/LinkProtected';
import AccessClose from '@pages/ExternalReviewer/AccessClose';
import ApprovalReviewerMediaLayout from '@layouts/ApprovalReviewerMediaLayout';
import { useAuth } from '@hooks/useAuth';
import EnterEmail from '@pages/ExternalReviewer/EnterEmail/EnterEmail';
import UploadAssetModal from '@components/Modals/UploadAssetModal';
import ApprovalModal from '@components/Modals/ApprovalModal';
import { WebSocketClientProvider } from '@hooks/useWebSocketClient';
import '@pages/ExternalReviewer/ExternalReviewer.less';
import UploadingAssetsProcess from '@components/UploadingAssetsProcess';
import { AssetsUppyProvider } from '@context/AssetsUppyContext';
import { OrganizationProvider } from '@components/OrganizationBoundary';
import { useFetch } from '@hooks/useFetch';
import { organizationListStateSelector } from '@redux/selectors/organizations';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchOrganizationsList } from '@redux/actions/organizations';

interface ApprovalReviewerProps {
  element: ReactElement;
}

export function ApprovalReviewerPage({ element }: ApprovalReviewerProps) {
  return (
    <div className="external_reviewer_container">
      <div className="external_reviewer_top">
        <LogoSvg />
      </div>
      <div className="external_reviewer_content">{element}</div>
      <div className="external_reviewer_bottom">
        New to StreamWork? Create your <a href="/login">own account</a> and
        start collaborating.
      </div>
    </div>
  );
}

interface ApprovalReviewerContainerProps {
  loader: ReactElement;
}

function ApprovalReviewerContainer({ loader }: ApprovalReviewerContainerProps) {
  const {
    isLoading,
    password,
    passwordRequired,
    passwordDoesNotMatch,
    isDisabled,
    commentMentions,
    setPassword
  } = useAssetReview();
  const dispatch = useTypedDispatch();

  const { user, error } = useAuth();
  const [beforeViewPage, setBeforeViewPage] = useState<'login' | 'password'>();
  if (!beforeViewPage) {
    if (!user) {
      setBeforeViewPage('login');
    } else if (passwordRequired && passwordDoesNotMatch) {
      setBeforeViewPage('password');
    }
  }
  const loading = useFetch({
    disabled: user?.type !== 'internal',
    key: `organizations`,
    selector: (state) => organizationListStateSelector(state).fetch,
    fetch: (fetchType) => dispatch(fetchOrganizationsList({ fetchType }))
  });
  const prevIsLoading = useRef(isLoading);
  if (isLoading !== prevIsLoading.current) {
    prevIsLoading.current = isLoading;
    if (!isLoading && beforeViewPage) {
      setBeforeViewPage(undefined);
    }
  }

  if (isDisabled || error?.error === 'not_aw_reviewer') {
    return (
      <ApprovalReviewerPage
        element={<AccessClose type="approval" awReason="not-a-reviewer" />}
      />
    );
  }
  if (beforeViewPage === 'login') {
    return <ApprovalReviewerPage element={<EnterEmail />} />;
  }
  if (beforeViewPage === 'password') {
    return (
      <ApprovalReviewerPage
        element={
          <LinkProtected
            password={password}
            passwordDoesNotMatch={passwordDoesNotMatch}
            setPassword={setPassword}
            loading={isLoading}
          />
        }
      />
    );
  }
  if (isLoading || loading) {
    return loader;
  }
  return (
    <OrganizationProvider organizations={[]}>
      <AssetsUppyProvider>
        <Routes>
          <Route path="/*" element={<ApprovalReviewerMediaLayout />}>
            <Route
              index
              element={<MediaViewer commentMentions={commentMentions} />}
            />
          </Route>
        </Routes>
        <ApprovalModal />
        <UploadAssetModal />
        <UploadingAssetsProcess />
      </AssetsUppyProvider>
    </OrganizationProvider>
  );
}

export default function ApprovalReviewer(
  props: ApprovalReviewerContainerProps
) {
  const { isAuthenticated, isLoading } = useAuth();
  if (!isAuthenticated && isLoading) return props.loader;
  return (
    <WebSocketClientProvider>
      <AssetReviewProvider type="approval">
        <ApprovalReviewerContainer {...props} />
      </AssetReviewProvider>
    </WebSocketClientProvider>
  );
}
